<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <category-form
          :category="category"
          v-if="category"
          :submit="updateCategoryAsync"
        ></category-form>
        <v-snackbar
          v-model="snackbar.visible"
          :color="snackbar.color"
          top
          right
        >
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.visible = false">
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import CategoryForm from "@/components/CategoryForm.vue";
import { isReadOnly } from "@/lib/helpers";
export default {
  props: ["updateCategorie"],
  mounted() {
    this.loadCategory();
    const snackbar = localStorage.getItem("snackbar");
    if (snackbar) {
      this.snackbar = JSON.parse(snackbar);
      localStorage.removeItem("snackbar");
    }
  },
  components: { CategoryForm },
  data: () => ({
    category: undefined,
    snackbar: {
      visible: false,
      text: "",
      color: "",
    },
  }),
  methods: {
    async updateCategoryAsync(category) {
      try {
        const { data } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/categories/${this.$route.params._id}`,
          data: category,
        });
        this.category = data;
        this.updateCategorie(data);
        this.snackbar = {
          visible: true,
          text: "Événement mis à jour",
          color: "success",
        };
      } catch (error) {
        console.log(error);
        this.snackbar = {
          visible: true,
          text: "Erreur lors de la mise à jour de l'événement",
          color: "error",
        };
      }
    },
    async loadCategory() {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/categories/${this.$route.params._id}`,
        });
        this.category = data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    "$route.params._id": function () {
      this.loadCategory();
    },
  },
};
</script>