<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <category-form
          :category="category"
          v-if="category"
          :submit="createCategoryAsync"
        ></category-form>
        <v-snackbar
          v-model="snackbar.visible"
          :color="snackbar.color"
          top
          right
        >
          {{ snackbar.text }}

          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.visible = false">
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import CategoryForm from "@/components/CategoryForm.vue";
import { isReadOnly } from "@/lib/helpers";
export default {
  components: { CategoryForm },
  props: ["loadCategories", "pushCategorie"],
  data: () => ({
    category: {
      name: undefined,
      _id: "create",
    },
    snackbar: {
      visible: false,
      text: "",
      color: "",
    },
  }),
  methods: {
    async createCategoryAsync(category) {
      try {
        delete category._id;
        const { data } = await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/categories`,
          data: category,
        });
        localStorage.setItem(
          "snackbar",
          JSON.stringify({
            visible: true,
            text: "Format d'événement créé",
            color: "success",
          })
        );
        this.pushCategorie(data);
        this.$router.push(`/categories/${data._id}`);
      } catch (error) {
        console.log(error);
        this.snackbar = {
          visible: true,
          text: "Erreur de création",
          color: "error",
        };
      }
    },
  },
};
</script>