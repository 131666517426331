<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <event-form
          :evt="evt"
          v-if="evt"
          :statuses="statuses"
          :categories="categories"
          :updateEvent="updateEvent"
          :submit="updateEventAsync"
          :loading="loading"
        ></event-form>

        <v-snackbar
          v-model="snackbar.visible"
          :color="snackbar.color"
          top
          right
        >
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.visible = false">
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <calendar-component-details
          :start="start"
          v-if="start"
          ref="calendardetails"
        ></calendar-component-details>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { format, parseISO, startOfWeek } from "date-fns";
import EventForm from "@/components/EventForm.vue";
import CalendarComponentDetails from "@/components/CalendarComponentDetails.vue";
import { isReadOnly } from "@/lib/helpers";
export default {
  components: { EventForm, CalendarComponentDetails },
  props: ["statuses", "categories"],
  data: () => ({
    evt: undefined,
    start: "",
    snackbar: {
      visible: false,
      text: "",
      color: "",
    },
    loading: false,
  }),
  mounted() {
    this.loadEvent();
    const snackbar = localStorage.getItem("snackbar");
    if (snackbar) {
      this.snackbar = JSON.parse(snackbar);
      localStorage.removeItem("snackbar");
    }
  },
  methods: {
    updateEvent(evt) {
      this.$refs.calendardetails.updateSelectedEvent(evt);
    },
    async updateEventAsync(evt) {
      delete evt.outlookId;
      this.loading = true;
      try {
        const { data } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/events/${this.$route.params._id}`,
          data: evt,
        });
        this.evt = data;
        this.snackbar = {
          visible: true,
          text: "Événement mis à jour",
          color: "success",
        };
      } catch (error) {
        console.log(error);
        this.snackbar = {
          visible: true,
          text: "Erreur lors de la mise à jour de l'événement",
          color: "error",
        };
      } finally {
        this.loading = false;
      }
    },
    async loadEvent() {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/events/${this.$route.params._id}`,
        });
        this.evt = data;
        this.start = format(
          startOfWeek(parseISO(data.startDate), { weekStartsOn: 1 }),
          "yyyy-MM-dd"
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    "$route.params._id": function () {
      this.loadEvent();
    },
  },
};
</script>