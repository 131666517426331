<template>
  <v-card class="mb-5">
    <v-card-text class="pa-10">
      <v-form v-if="editedEvent" v-model="valid" :disabled="isReadOnly($keycloak.tokenParsed)">
        <v-text-field
          v-model="editedEvent._id"
          label="ID"
          outlined
          dense
          disabled
          v-show="editedEvent._id !== 'create'"
        ></v-text-field>
        <v-text-field
          v-model="editedEvent.title"
          label="Titre *"
          outlined
          dense
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="editedEvent.description"
          label="Description *"
          outlined
          dense
          :rules="[rules.required]"
        ></v-text-field>
        <v-row class="my-1">
          <v-col class="py-0">
            <v-menu
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedStartDate"
                  label="Date de début *"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="startMenu = false"
                locale="fr-ch"
                :max="this.endDate"
              ></v-date-picker> </v-menu
          ></v-col>
          <v-col sm="4" class="py-0"
            ><v-text-field
              outlined
              dense
              label="Heure de début *"
              hint="Format HH:mm"
              persistent-hint
              v-model="startTime"
              v-mask="'##:##'"
              :rules="[rules.required]"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-menu
              v-model="endMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedEndDate"
                  label="Date de fin *"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="endMenu = false"
                locale="fr-ch"
                :min="this.startDate"
                :rules="[rules.required, rules.timeRange]"
              ></v-date-picker> </v-menu
          ></v-col>
          <v-col sm="4" class="pt-0"
            ><v-text-field
              outlined
              dense
              label="Heure de fin *"
              hint="Format HH:mm"
              persistent-hint
              v-model="endTime"
              v-mask="'##:##'"
              :rules="[rules.required, rules.timeRange]"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-text-field
          v-model="editedEvent.place"
          label="Lieu proposé *"
          outlined
          dense
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="editedEvent.confirmedPlace"
          label="Lieu accordé"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          v-model="editedEvent.entity"
          label="Entité organisatrice *"
          outlined
          dense
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="editedEvent.nbPeople"
          label="Nbre de participants attendu"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          v-model="editedEvent.target"
          label="Public concerné"
          outlined
          dense
        ></v-text-field>
        <v-autocomplete
          v-model="selectedContact"
          :items="contacts"
          :search-input.sync="searchContact"
          label="Personne de contact *"
          outlined
          dense
          @input="clearSearchContact"
          no-data-text="Tapez le nom d'une personne..."
          :rules="[rules.required]"
        />
        <v-autocomplete
          v-model="selectedAuthor"
          :items="authors"
          :search-input.sync="searchAuthor"
          label="Auteur *"
          outlined
          dense
          @input="clearSearchAuthor"
          no-data-text="Tapez le nom d'une personne..."
          :rules="[rules.required]"
        />
        <v-select
          outlined
          dense
          label="Rôle"
          :items="roles"
          v-model="editedEvent.creatorRole"
        ></v-select>
        <v-select
          outlined
          dense
          label="Statut"
          :items="statuses"
          v-model="editedEvent.status"
        ></v-select>
        <v-select
          outlined
          dense
          label="Catégorie"
          :items="types"
          v-model="editedEvent.type"
          clearable
        ></v-select>
        <v-select
          outlined
          dense
          label="Format d'événement"
          :items="categories"
          v-model="editedEvent.category"
          clearable
        ></v-select>
        <v-text-field
          v-model="editedEvent.notice"
          label="Remarques"
          outlined
          dense
        ></v-text-field>
        <v-file-input
          truncate-length="30"
          accept="application/pdf"
          outlined
          label="Pièce jointe"
          v-model="currentDocument"
          @change="handleAttachmentUpload"
          @click:clear="removeAttachment"
          @click:append="downloadFile"
          :loading="attachmentUploading"
          hint="Format PDF"
          :persistent-hint="true"
          :append-icon="
            currentDocument ? 'mdi-cloud-download-outline' : undefined
          "
          dense
        ></v-file-input>
        <v-checkbox
          v-model="editedEvent.directionShouldCome"
          label="Présence de la direction souhaitée"
          class="my-0"
        ></v-checkbox>
        <v-autocomplete
          v-model="selectedDirector"
          :items="directors"
          :search-input.sync="searchDirector"
          label="Membre de la direction"
          outlined
          dense
          @input="clearSearchDirector"
          no-data-text="Tapez le nom d'une personne..."
        />
        <v-text-field
          outlined
          dense
          label="Lien gestion de projet"
          :rules="[rules.url]"
          ref="projectManagementLink"
          v-model="editedEvent.projectManagementLink"
          :append-icon="
            editedEvent.projectManagementLink &&
            this.$refs.projectManagementLink &&
            !this.$refs.projectManagementLink.hasError
              ? 'mdi-link'
              : undefined
          "
          @click:append="openLink"
        ></v-text-field>
        <div class="mt-5 subtitle-2">Communication de l'événement</div>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-checkbox
              v-model="editedEvent.externalCommunication"
              label="Communication externe (site)"
              color="primary"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-checkbox
              v-model="editedEvent.internalCommunication"
              label="Communication interne (intranet)"
              color="primary"
            ></v-checkbox>
          </v-col>
        </v-row>
        <div class="mt-3 subtitle-2">Notifications</div>
        <v-select
          outlined
          dense
          multiple
          :items="emails"
          label="E-mails"
          class="mt-2"
          v-model="editedEvent.emails"
        ></v-select>
        <v-btn
          outlined
          block
          class="mb-3"
          @click="
            submit({
              ...editedEvent,
              author: selectedAuthor,
              contact: selectedContact,
              directionMember: selectedDirector,
            })
          "
          :disabled="!valid"
          :loading="loading"
          v-if="!isReadOnly($keycloak.tokenParsed)"
          >Soumettre</v-btn
        >
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  format,
  parseISO,
  formatISO,
  parse,
  setMinutes,
  setHours,
  isWithinInterval,
} from "date-fns";
import { mask } from "vue-the-mask";
import _ from "lodash";
import axios from "axios";
import { isReadOnly } from "@/lib/helpers";
const urlRegex = new RegExp(
  "^" +
    // protocol identifier (optional)
    // short syntax // still required
    "(?:(?:(?:https?|ftp):)?\\/\\/)" +
    // user:pass BasicAuth (optional)
    "(?:\\S+(?::\\S*)?@)?" +
    "(?:" +
    // IP address exclusion
    // private & local networks
    "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
    "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
    "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broadcast addresses
    // (first & last IP address of each class)
    "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
    "|" +
    // host & domain names, may end with dot
    // can be replaced by a shortest alternative
    // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
    "(?:" +
    "(?:" +
    "[a-z0-9\\u00a1-\\uffff]" +
    "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
    ")?" +
    "[a-z0-9\\u00a1-\\uffff]\\." +
    ")+" +
    // TLD identifier name, may end with dot
    "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
    ")" +
    // port number (optional)
    "(?::\\d{2,5})?" +
    // resource path (optional)
    "(?:[/?#]\\S*)?" +
    "$",
  "i"
);

export default {
  directives: { mask },
  props: ["evt", "statuses", "categories", "updateEvent", "submit", "loading"],
  data: () => ({
    valid: false,
    searchContact: null,
    searchAuthor: null,
    searchDirector: null,
    selectedContact: null,
    selectedAuthor: null,
    selectedDirector: null,
    contacts: [],
    authors: [],
    directors: [],
    editedEvent: undefined,
    startMenu: false,
    endMenu: false,
    rules: {
      required: (v) => !!v || "Ce champ est requis.",
      url: (v) => {
        if (v && v !== "" && !urlRegex.test(v)) {
          return "Format invalide";
        }
        return true;
      },
      timeRange: (v) => {
        if (v.length < 5) {
          return "Format invalide";
        }
        if (v.length === 5) {
          const parsed = parse(v, "HH:mm", new Date());
          const start = setMinutes(setHours(new Date(), 0), 0);
          const end = setMinutes(setHours(new Date(), 23), 59);
          if (parsed.toString() === "Invalid Date") {
            return "Format invalide";
          }
          if (!isWithinInterval(parsed, { start, end })) {
            return "Compris entre 00:01 et 23:59";
          }
        }
        return true;
      },
    },
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    roles: [
      { text: "Employé", value: "employee" },
      { text: "Étudiant", value: "student" },
    ],
    attachmentUploading: false,
    currentDocument: null,
    types: ["Interne", "Externe", "Promotion"],
    emails: [
      "alumni@heig-vd.ch",
      "communication@heig-vd.ch",
      "helpdesk@heig-vd.ch",
      "mobilite@heig-vd.ch",
      "orangeraie@heig-vd.ch",
      "secretariat.reception@heig-vd.ch",
    ],
    isReadOnly
  }),
  mounted() {
    this.editedEvent = this.evt;
    if (this.evt.startDate) {
      this.startDate = format(parseISO(this.evt.startDate), "yyyy-MM-dd");
      this.startTime = format(parseISO(this.evt.startDate), "HH:mm");
    }
    if (this.evt.endDate) {
      this.endDate = format(parseISO(this.evt.endDate), "yyyy-MM-dd");
      this.endTime = format(parseISO(this.evt.endDate), "HH:mm");
    }
    this.authors = [this.evt.author];
    this.contacts = [this.evt.contact];
    this.directors = [this.evt.directionMember];
    this.selectedContact = this.evt.contact;
    this.selectedAuthor = this.evt.author;
    this.selectedDirector = this.evt.directionMember;

    if (this.evt.attachment) {
      this.currentDocument = new File([], this.evt.attachment.substr(22), {
        type: "text-plain",
      });
    }
  },
  methods: {
    openLink() {
      window.open(this.editedEvent.projectManagementLink);
    },
    downloadFile: async function () {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/files/${this.editedEvent.attachment}`,
          responseType: "arraybuffer",
        });
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.editedEvent.attachment.substr(22));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the PDF:", error);
      }
    },
    handleAttachmentUpload: async function (file) {
      if (file) {
        this.offerUploading = false;
        try {
          const formData = new FormData();
          formData.append("file", file);
          const { data } = await axios({
            method: "post",
            url: `${process.env.VUE_APP_API_URI}/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/upload`,
            data: formData,
            headers: {
              "content-Type": "multipart/form-data",
            },
          });
          this.editedEvent.attachment = data.filename;
          this.offerUploading = false;
        } catch (e) {
          this.offerUploading = false;
        }
      }
    },
    removeAttachment: function () {
      this.editedEvent.attachment = "";
    },
    parseAndFormatISO(date, time) {
      return formatISO(
        parse(`${date} ${time}`, "yyyy-MM-dd HH:mm", new Date())
      );
    },
    computeISODate(type) {
      if (
        this[`${type}Date`] &&
        this[`${type}Time`] &&
        this[`${type}Time`].length === 5
      ) {
        this.editedEvent[`${type}Date`] = this.parseAndFormatISO(
          this[`${type}Date`],
          this[`${type}Time`]
        );
      }
    },
    async queryPicker(v, key) {
      const { data: contacts } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_PEOPLEPICKER_URI}/search?s=${v}`,
        headers: {
          "x-api-key": process.env.VUE_APP_PEOPLEPICKER_KEY,
        },
      });
      this[key] = [...contacts.map((c) => c.email)];
      this[key] = _.uniqBy(this[key]);
    },
    clearSearchContact() {
      this.searchContact = null;
    },
    clearSearchAuthor() {
      this.searchAuthor = null;
    },
    clearSearchDirector() {
      this.searchDirector = null;
    },
    async checkConflicts() {
      try {
        const { data: conflict } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/events/check-conflict?startDate=${this.editedEvent.startDate}&endDate=${this.editedEvent.endDate}&eventId=${this.editedEvent._id}`,
          headers: { "x-api-key": process.env.VUE_APP_API_KEY },
        });
        this.editedEvent.conflict = conflict;
        this.editedEvent.conflictAknowledged = true;
        this.updateEvent(this.editedEvent);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    startDateTime() {
      return `${this.startDate}|${this.startTime}`;
    },
    endDateTime() {
      return `${this.endDate}|${this.endTime}`;
    },
    computedStartDate() {
      return this.startDate
        ? format(parseISO(this.startDate), "dd.MM.yyyy")
        : "";
    },
    computedEndDate() {
      return this.endDate ? format(parseISO(this.endDate), "dd.MM.yyyy") : "";
    },
  },
  watch: {
    editedEvent: {
      handler: function (val) {
        this.updateEvent(val);
      },
      deep: true,
    },
    startDateTime() {
      this.computeISODate("start");
      if (
        this.editedEvent.startDate &&
        this.editedEvent.endDate &&
        this.startTime.length === 5 &&
        this.endTime.length === 5
      ) {
        this.checkConflicts();
      }
    },
    endDateTime() {
      this.computeISODate("end");
      if (
        this.editedEvent.startDate &&
        this.editedEvent.endDate &&
        this.startTime.length === 5 &&
        this.endTime.length === 5
      ) {
        this.checkConflicts();
      }
    },
    searchContact: _.debounce(function (val) {
      val && val !== this.selectedContact && this.queryPicker(val, "contacts");
    }, 300),
    searchAuthor: _.debounce(function (val) {
      val && val !== this.selectedAuthor && this.queryPicker(val, "authors");
    }, 300),
    searchDirector: _.debounce(function (val) {
      val && val !== this.selectedAuthor && this.queryPicker(val, "directors");
    }, 300),
  },
};
</script>