<template>
  <v-app>
    <v-app-bar app flat color="grey lighten-4">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" overlay-opacity="0">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar tile>
            <img
              src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
              alt="Logo HEIG-VD"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Griffin</v-list-item-title>
            <v-list-item-subtitle>Interface de gestion</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item link to="/" exact>
          <v-list-item-icon>
            <v-icon>mdi-calendar-month</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Calendrier</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-3"></v-divider>
        <v-list-item
          link
          to="/events/create"
          exact
          v-if="!isReadOnly($keycloak.tokenParsed)"
        >
          <v-list-item-icon>
            <v-icon>mdi-plus-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Ajouter un événement</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/events" exact>
          <v-list-item-icon>
            <v-icon>mdi-calendar-text-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Tous les événements</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/events?status=pending"
          exact
          v-if="!isReadOnly($keycloak.tokenParsed)"
        >
          <v-list-item-icon>
            <v-icon>mdi-calendar-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Événements à traiter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/events?status=upcoming" exact>
          <v-list-item-icon>
            <v-icon>mdi-timer-settings-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Événements à venir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/events?status=past" exact>
          <v-list-item-icon>
            <v-icon>mdi-timer-check-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Événements passés</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          class="my-3"
          v-if="!isReadOnly($keycloak.tokenParsed)"
        ></v-divider>
        <v-list-item
          link
          to="/categories/create"
          exact
          v-if="!isReadOnly($keycloak.tokenParsed)"
        >
          <v-list-item-icon>
            <v-icon>mdi-plus-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Ajouter un format d'événement</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/categories"
          exact
          v-if="!isReadOnly($keycloak.tokenParsed)"
        >
          <v-list-item-icon>
            <v-icon>mdi-shape-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Formats d'événement</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="ma-md-10 ma-sm-0">
      <router-view
        :statuses="statuses"
        :categories="categories"
        :loadCategories="loadCategories"
        :pushCategorie="pushCategorie"
        :deleteCategorie="deleteCategorie"
        :updateCategorie="updateCategorie"
      />
    </v-main>
  </v-app>
</template>

<script>
import UserProfile from "@/components/UserProfile";
import axios from "axios";
import { isReadOnly } from "@/lib/helpers";

export default {
  name: "App",
  components: { UserProfile },
  data: () => ({
    drawer: null,
    statuses: [
      { text: "À traiter", value: "pending" },
      { text: "Accepté", value: "accepted" },
      { text: "Refusé", value: "refused" },
      { text: "Annulé", value: "canceled" },
    ],
    loading: false,
    categories: [],
    snackbar: {
      visible: false,
      text: "",
      color: "success",
    },
    isReadOnly,
  }),
  async mounted() {
    this.loadCategories();
    console.log(isReadOnly(this.$keycloak.tokenParsed));
  },
  methods: {
    pushCategorie(c) {
      this.categories.push({ text: c.name, value: c._id });
    },
    deleteCategorie(c) {
      this.categories = this.categories.filter((ca) => ca.value !== c._id);
    },
    updateCategorie(c) {
      this.categories.find((ca) => ca.value === c._id).name = c.name;
    },
    async loadCategories() {
      this.loading = true;
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/${
            isReadOnly(this.$keycloak.tokenParsed) ? "read-only" : "admin"
          }/categories`,
        });
        this.categories = data.map((c) => ({ text: c.name, value: c._id }));
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.separator {
  display: inline-block;
}

.v-calendar {
  height: auto !important;
}
.v-calendar-monthly {
  height: 50vh !important;
}
</style>