<template>
  <v-row>
    <v-col>
      <v-sheet>
        <v-toolbar flat>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mois</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semaine</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Jour</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet style="height: --webkit-fill-available">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :events="events"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          :weekdays="weekdays"
          locale="fr"
          min-height="670px"
        >
        </v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { parseISO, format } from "date-fns";
import { isReadOnly } from "@/lib/helpers";

export default {
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Mois",
      week: "Semaine",
      day: "Jour",
    },
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    events: [],
    statuses: {
      accepted: "green",
      pending: "orange",
      refused: "red",
      processing: "grey",
      canceled: 'grey'
    },
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ event }) {
      this.$router.push(`/events/${event._id}`);
    },
    async updateRange({ start, end }) {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/events?start=${start.date}&end=${end.date}&type=calendar`,
        });
        this.events = data.map((d) => ({
          _id: d._id,
          name: `${format(parseISO(d.startDate), "HH:mm")} - ${d.title}`,
          start: parseISO(d.startDate),
          end: parseISO(d.endDate),
          color: `${this.statuses[d.status]} darken-1`,
          timed: true,
        }));
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
 .v-calendar-monthly {
   height: 670px !important;
 }
</style>