<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <div>
              Événements
              <v-btn
                icon
                v-if="
                  !$route.query.status ||
                  ['upcoming', 'past'].indexOf($route.query.status) !== -1
                "
                @click="downloadReport"
                :ripple="false"
                plain
                ><v-icon>mdi-microsoft-excel</v-icon></v-btn
              >
            </div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher..."
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            :items="events"
            :search="search"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 30, 50],
              itemsPerPageText: 'Élements par page',
            }"
          >
            <template v-slot:body="{ items }">
              <tbody class="text-left">
                <tr
                  v-for="item in items"
                  :key="item._id"
                  @click="$router.push(`/events/${item._id}`)"
                >
                  <td class="text-truncate" style="max-width: 250px">
                    {{ item.title }}
                  </td>
                  <td class="text-truncate" style="max-width: 250px">
                    {{ item.place }}
                  </td>
                  <td class="text-truncate" style="max-width: 250px">
                    {{ format(parseISO(item.startDate), "dd.MM.yyyy HH:mm") }}
                  </td>
                  <td class="text-truncate" style="max-width: 250px">
                    {{ format(parseISO(item.endDate), "dd.MM.yyyy HH:mm") }}
                  </td>
                  <td class="text-truncate" style="max-width: 250px">
                    {{ format(parseISO(item.createdAt), "dd.MM.yyyy HH:mm") }}
                  </td>
                  <td class="text-truncate" style="max-width: 250px">
                    {{ item.contact }}
                  </td>
                  <td class="text-truncate" style="max-width: 250px">
                    {{ item.type }}
                  </td>
                  <td class="text-truncate" style="max-width: 250px">
                    {{ item.category ? item.category.name : "" }}
                  </td>
                  <td class="text-truncate" style="max-width: 250px">
                    <v-chip
                      :color="getColor(item.status)"
                      class="white--text"
                      >{{
                        statuses.find((s) => s.value === item.status).text
                      }}</v-chip
                    >
                  </td>
                </tr>
              </tbody></template
            >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
import { format, parseISO } from "date-fns";
import fileDownload from "js-file-download";
import { isReadOnly } from "@/lib/helpers";

export default {
  props: ["statuses"],
  data: () => ({
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
    events: undefined,
    search: "",
    loading: false,
    total: 0,
    options: {},
    headers: [
      { text: "Titre", value: "title" },
      { text: "Lieu proposé", value: "place" },
      { text: "Début", value: "startDate" },
      { text: "Fin", value: "endDate" },
      { text: "Date de création", value: "createdAt" },
      { text: "Personne de contact", value: "contact" },
      { text: "Catégorie", value: "type" },
      { text: "Format d'événement", value: "category" },
      { text: "Statut", value: "status" },
    ],
    format,
    parseISO,
  }),
  methods: {
    async downloadReport() {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/events/export?type=${
          this.$route.query.status || ""
        }`,
        responseType: "arraybuffer",
      });
      fileDownload(data, "requests.xlsx");
    },
    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${
          process.env.VUE_APP_API_URI
        }/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/events?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&search=${
          this.search
        }&type=list&status=${this.$route.query.status || ""}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      return {
        items,
        total,
      };
    },
    getColor(status) {
      if (status === "pending") return "warning";
      if (status === "accepted") return "success";
      if (status === "refused") return "error";
      return "grey";
    },
  },
  watch: {
    options: {
      async handler() {
        const data = await this.getDataFromApi();
        this.events = data.items;
        this.total = data.total;
      },

      deep: true,
    },
    "$route.query.status": async function () {
      const data = await this.getDataFromApi();
      this.events = data.items;
      this.total = data.total;
    },
    search: debounce(async function (value) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${
          process.env.VUE_APP_API_URI
        }/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/events?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&search=${value}&type=list&status=${
          this.$route.query.status || ""
        }`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      this.events = items;
      this.total = total;
    }, 500),
  },
};
</script>