<template>
  <v-card>
    <v-card-text>
      <div>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mois</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semaine</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Jour</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="focus"
            :events="events"
            :type="type"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:event="showEvent"
            @change="updateRange"
            :weekdays="weekdays"
            locale="fr"
            :start="start"
          >
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
            max-width="350px"
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.menuColor" dark>
                <!-- eslint-disable-next-line -->
                <v-toolbar-title v-html="selectedEvent.title"></v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text class="text-center">
                <div class="subtitle-2">Début</div>
                <div>
                  {{
                    parse(
                      selectedEvent.start,
                      "yyyy-MM-dd HH:mm",
                      new Date()
                    ).toLocaleString("fr-CH", {
                      timeZone: "Europe/Zurich",
                      timeStyle: "short",
                      dateStyle: "long",
                    })
                  }}
                </div>
                <div class="subtitle-2 mt-3">Fin</div>
                <div>
                  {{
                    parse(
                      selectedEvent.end,
                      "yyyy-MM-dd HH:mm",
                      new Date()
                    ).toLocaleString("fr-CH", {
                      timeZone: "Europe/Zurich",
                      timeStyle: "short",
                      dateStyle: "long",
                    })
                  }}
                </div>
                <div class="subtitle-2 mt-3">Lieu proposé</div>
                <div>{{ selectedEvent.place }}</div>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { parseISO, format, parse } from "date-fns";
import { isReadOnly } from "@/lib/helpers";

export default {
  props: ["start"],
  data: () => ({
    selectedEvent: {},
    selectedElement: undefined,
    selectedOpen: false,
    focus: "",
    type: "week",
    typeToLabel: {
      month: "Mois",
      week: "Semaine",
      day: "Jour",
    },
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    events: [],
    statuses: {
      accepted: "green",
      pending: "orange",
      refused: "red",
      canceled: "grey",
    },
    format,
    parseISO,
    parse,
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    showEvent({ nativeEvent, event }) {
      if (event._id !== "create") {
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          );
        };
        if (this.selectedOpen) {
          this.selectedOpen = false;
          requestAnimationFrame(() => requestAnimationFrame(() => open()));
        } else {
          open();
        }
      }
      nativeEvent.stopPropagation();
    },
    updateSelectedEvent(evt) {
      if (evt.title && evt.startDate && evt.endDate) {
        if (this.events.find((e) => e._id === evt._id)) {
          this.events.find((e) => e._id === evt._id).name = `${format(
            parseISO(evt.startDate),
            "HH:mm"
          )} - ${evt.title}`;
          this.events.find((e) => e._id === evt._id).start = format(
            parseISO(evt.startDate),
            "yyyy-MM-dd HH:mm"
          );
          this.events.find((e) => e._id === evt._id).end = format(
            parseISO(evt.endDate),
            "yyyy-MM-dd HH:mm"
          );
          this.events.find((e) => e._id === evt._id).color = evt.conflict
            ? "error"
            : "success";
          this.events.find((e) => e._id === evt._id).place = evt.place;
          this.events.find((e) => e._id === evt._id).menuColor =
            this.statuses[evt.status];
          this.events.find((e) => e._id === evt._id).title = evt.title;
          this.events.find((e) => e._id === evt._id).status = evt.status;
        } else {
          this.events.push({
            _id: evt._id,
            name: `${format(parseISO(evt.startDate), "HH:mm")} - ${evt.title}`,
            start: format(parseISO(evt.startDate), "yyyy-MM-dd HH:mm"),
            end: format(parseISO(evt.endDate), "yyyy-MM-dd HH:mm"),
            color: evt.conflict ? "error" : "success",
          });
        }
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async updateRange({ start, end }) {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/${isReadOnly(this.$keycloak.tokenParsed)?"read-only" : "admin"}/events?start=${start.date}&end=${end.date}&type=calendar`,
        });
        this.events = [
          ...this.events,
          ...data.map((d) => ({
            _id: d._id,
            name: `${format(parseISO(d.startDate), "HH:mm")} - ${d.title}`,
            start: format(parseISO(d.startDate), "yyyy-MM-dd HH:mm"),
            end: format(parseISO(d.endDate), "yyyy-MM-dd HH:mm"),
            color:
              d._id === this.$route.params._id
                ? d.conflict
                  ? "error"
                  : "success"
                : "grey",
            timed: true,
            menuColor: this.statuses[d.status],
            status: d.status,
            title: d.title,
            place: d.place,
          })),
        ].filter(
          (value, index, self) =>
            index === self.findIndex((t) => t._id === value._id)
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>